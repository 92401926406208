@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

:root {
    --blueprint-color-primary: #2d72d2;
    --blueprint-color-warning: #c87619;
    --blueprint-color-danger: #cd4246;
    --blueprint-color-success: #238551;
}

/* Customize Blueprint */
.bp4-multi-select-popover .bp4-menu {
    max-height: 300px;
    overflow: auto;
}

.bp4-select-popover .bp4-menu {
    max-height: 300px;
    overflow: auto;
}
